<template>
  <div class="main">
    <heads></heads>
    <banners></banners>
    <islogin />
    <div class="content">
      <div class="zhicheng-list">
        <div class="zhicheng-list-title">请选择地区</div>
        <div class="zhicheng-list-con">
          <el-button
            type="primary"
            size="small"
            plain
            v-for="(item, key) in diqulist"
            :key="key"
            @click="changediqu(item.Id)"
            >{{ item.gxname }}</el-button
          >
        </div>
      </div>
      <div class="zhicheng-list" v-if="xiala2.length > 0">
        <div class="zhicheng-list-title">请选择职称</div>
        <div class="zhicheng-list-con">
          <el-button
            type="primary"
            size="small"
            plain
            v-for="(item, key) in xiala2"
            :key="key"
            @click="changexiala2(item.Id)"
            >{{ item.gxname }}</el-button
          >
        </div>
      </div>
      <div class="zhicheng-list" v-if="xiala3.length > 0">
        <div class="zhicheng-list-title">请选择专业</div>
        <div class="zhicheng-list-con">
          <el-button
            type="primary"
            size="small"
            plain
            v-for="(item, key) in xiala3"
            :key="key"
            @click="changexiala3(item.Id)"
            >{{ item.gxname }}</el-button
          >
        </div>
      </div>
      <div class="zhicheng-list" v-if="xiala4.length > 0">
        <div class="zhicheng-list-title">请选择级别</div>
        <div class="zhicheng-list-con">
          <el-button
            type="primary"
            size="small"
            plain
            v-for="(item, key) in xiala4"
            :key="key"
            @click="changexiala4(item.Id)"
            >{{ item.gxname }}</el-button
          >
        </div>
      </div>
      <div class="zhicheng-list" v-if="xiala5.length > 0">
        <div class="zhicheng-list-title">请选择单位</div>
        <div class="zhicheng-list-con">
          <el-button
            type="primary"
            size="small"
            plain
            v-for="(item, key) in xiala5"
            :key="key"
            @click="changexiala5(item.Id)"
            >{{ item.gxname }}</el-button
          >
        </div>
      </div>
      <div class="zhicheng-yixuanze">
        已选择：{{ diquname == "" ? "" : diquname
        }}{{ xiala2name == "" ? "" : "-" + xiala2name
        }}{{ xiala3name == "" ? "" : "-" + xiala3name
        }}{{ xiala4name == "" ? "" : "-" + xiala4name
        }}{{ xiala5name == "" ? "" : "-" + xiala5name }}
      </div>
      <div class="zhicheng-btn" v-if="isbtn">
        <el-button type="primary" size="large" @click="clickxzwc"
          >选择完成</el-button
        >
      </div>
    </div>

    <footers></footers>
  </div>
</template>

<script setup>
import { get } from "@/utils/index";
import { ElMessage } from "element-plus";
import { reactive, getCurrentInstance, ref } from "vue";
import { useRouter } from "vue-router";
const { proxy } = getCurrentInstance();
const router = useRouter();

let form = reactive({
  sbzczy: "", // 需要提交的名称（全部名称，逗号隔开）
  sbzcjb: "", // 需要提交的id（最后一级的id）
});

// 是否显示按钮
let isbtn = ref(false);
// 职称地区
let diqulist = reactive([]);
let diquvalue = ref("");
let diquname = ref("");
// 选中地区id
let diquid = ref(0);
//获取到的全部职称
let zhichengyuan = reactive([]);
// 二级下拉框
let xiala2 = reactive([]);
let xiala2value = ref("");
let xiala2name = ref("");
// 三级下拉框
let xiala3 = reactive([]);
let xiala3value = ref("");
let xiala3name = ref("");
// 四级下拉框
let xiala4 = reactive([]);
let xiala4value = ref("");
let xiala4name = ref("");
// 五级下拉框
let xiala5 = reactive([]);
let xiala5value = ref("");
let xiala5name = ref("");

// 获取地区
const getarea = () => {
  get("/journalTitle/GetProvinceList").then((res) => {
    // console.log(res);
    diqulist.length = 0;
    diqulist.push(...res.data);
  });
};
getarea();

// 选择了地区
const changediqu = (val) => {
  diquid.value = val;
  diquname.value = diqulist.filter((e) => {
    return e.Id == val;
  })[0].gxname;
  // 清空
  xiala2value.value = "";
  xiala2name.value = "";
  xiala2.length = 0;
  xiala3value.value = "";
  xiala3name.value = "";
  xiala3.length = 0;
  xiala4value.value = "";
  xiala4name.value = "";
  xiala4.length = 0;
  xiala5value.value = "";
  xiala5name.value = "";
  xiala5.length = 0;
  // 隐藏btn
  isbtn.value = false;
  getzhicheng();
};

// 获取地区下的全部职称
const getzhicheng = () => {
  get("/journalTitle/GetProvinceNextALLList/"+diquid.value, {
    provinceId: diquid.value,
  }).then((res) => {
    // console.log(res)
    zhichengyuan.length = 0;
    zhichengyuan.push(...res.data);
    xuanranerji();
  });
};
// 渲染二级菜单
const xuanranerji = () => {
  xiala2.length = 0;
  xiala2.push(
    ...zhichengyuan.filter((e) => {
      return e.parentid == diquid.value;
    })
  );
};
// 选择二级菜单
const changexiala2 = (val) => {
  xiala2name.value = xiala2.filter((e) => {
    return e.Id == val;
  })[0].gxname;
  // 清空
  xiala3value.value = "";
  xiala3name.value = "";
  xiala3.length = 0;
  xiala4value.value = "";
  xiala4name.value = "";
  xiala4.length = 0;
  xiala5value.value = "";
  xiala5name.value = "";
  xiala5.length = 0;
  // 隐藏btn
  isbtn.value = false;
  xiala3.push(
    ...zhichengyuan.filter((e) => {
      return e.parentid == val;
    })
  );
};
// 选择3级菜单
const changexiala3 = (val) => {
  xiala3name.value = xiala3.filter((e) => {
    return e.Id == val;
  })[0].gxname;
  // 清空
  xiala4value.value = "";
  xiala4name.value = "";
  xiala4.length = 0;
  xiala5value.value = "";
  xiala5name.value = "";
  xiala5.length = 0;
  // 隐藏btn
  isbtn.value = false;
  xiala4.push(
    ...zhichengyuan.filter((e) => {
      return e.parentid == val;
    })
  );
  // 没有四级菜单
  if (xiala4.length == 0) {
    // 保存id
    form.sbzcjb = val;
    // 拼接名称
    let sbzczytemp = [];
    sbzczytemp.push(diquname.value);
    sbzczytemp.push(xiala2name.value);
    sbzczytemp.push(xiala3name.value);
    form.sbzczy = JSON.stringify(sbzczytemp); 
    // 显示提交
    isbtn.value = true;
  }
};
// 选择4级菜单
const changexiala4 = (val) => {
  xiala4name.value = xiala4.filter((e) => {
    return e.Id == val;
  })[0].gxname;
  // 清空
  xiala5value.value = "";
  xiala5name.value = "";
  xiala5.length = 0;
  // 隐藏btn
  isbtn.value = false;
  xiala5.push(
    ...zhichengyuan.filter((e) => {
      return e.parentid == val;
    })
  );
  // 没有5级菜单
  if (xiala5.length == 0) {
    // 保存id
    form.sbzcjb = val;
    // 拼接名称
    let sbzczytemp = [];
    sbzczytemp.push(diquname.value);
    sbzczytemp.push(xiala2name.value);
    sbzczytemp.push(xiala3name.value);
    sbzczytemp.push(xiala4name.value);
    form.sbzczy = JSON.stringify(sbzczytemp);
    // 显示提交
    isbtn.value = true;
  }
};
// 选择5级菜单
const changexiala5 = (val) => {
  xiala5name.value = xiala5.filter((e) => {
    return e.Id == val;
  })[0].gxname;
  // 保存id
  form.sbzcjb = val;
  // 拼接名称
  let sbzczytemp = [];
  sbzczytemp.push(diquname.value);
  sbzczytemp.push(xiala2name.value);
  sbzczytemp.push(xiala3name.value);
  sbzczytemp.push(xiala4name.value);
  sbzczytemp.push(xiala5name.value);
  form.sbzczy = JSON.stringify(sbzczytemp);
  // 显示提交
  isbtn.value = true;
};

// 选择完成
const clickxzwc = () => {
  let qikanname =
   ( diquname.value == "" ? "" : diquname.value ) + ( xiala2name.value == "" ? "" : "-" + xiala2name.value )
    + ( xiala3name.value == "" ? "" : "-" + xiala3name.value ) + ( xiala4name.value == "" ? "" : "-" + xiala4name.value )
    + ( xiala5name.value == "" ? "" : "-" + xiala5name.value )

  router.push({
    path: "/xzzc/xsxq",
    query: {
      // id
      id: form.sbzcjb,
      // 名称用于显示
      qikan: encodeURIComponent(qikanname),
      // 名称用于提交接口 
      qikanlist:encodeURIComponent(form.sbzczy)
    },
  });
};
</script>

<style scoped>
:deep(.zhicheng-list-con .el-button + .el-button) {
  margin-left: 0;
}
:deep(.zhicheng-list-con .el-button) {
  margin: 0 12px 8px 0;
}
.main {
  position: relative;
  background: #fbfcff;
}
.content {
  width: 1200px;
  height: auto;
  padding: 30px 0;
  margin: auto;
}
.zhicheng-list-title {
  background: #ecf5ff;
  padding: 8px 8px;
  font-size: 14px;
  font-weight: bold;
}
.zhicheng-list-con {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 10px 0;
}
.zhicheng-list {
  margin: 10px 0;
}
.zhicheng-btn {
  margin: 20px 0 0 0;
  text-align: center;
}
.zhicheng-yixuanze {
  font-size: 14px;
  font-weight: bold;
}
</style>
